.example {
  max-width: 1061px;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;

  margin-bottom: 153px;

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; 
    text-transform: uppercase;

    margin-bottom: 60px;
  }

  &__slider {
    display: flex;
    justify-content: center;
  }

  &__slider .slick-list {
    max-width: 1000px;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 425px;
  }

  &__slider .alice-carousel {
    text-align: center;
    max-width: 1000px;
    width: 100%;

  }

  &__slider .slick-slide {
    margin-left: 15px;
    width: 319px;
  }

  &__slider .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    z-index: 99;
  }

  &__slider .alice-carousel__prev-btn-item {
    left: -65px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    z-index: 99;
  }

  &__slider .alice-carousel__prev-btn-item span::after {
    content: '';
    width: 60px;
    height: 60px;

    position: absolute;
    top: 0;
    left: 0;

    transform: rotate(90deg);

    background-image: url('../../image/icons/open-arrow.webp');
    background-size: 60px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__slider .alice-carousel__next-btn-wrapper {
    right: -60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    z-index: 99;
  }

  &__slider .alice-carousel__next-btn-wrapper span::after {
    content: '';
    width: 60px;
    height: 60px;

    position: absolute;
    top: 0;
    left: 0;

    transform: rotate(270deg);

    background-image: url('../../image/icons/open-arrow.webp');
    background-size: 60px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1150px) {
  .example {

    &__slider .alice-carousel {
      max-width: 800px;
      width: 100%;
  
    }
  }
}

@media screen and (max-width: 950px) {
  .example {
  

    &__slider .alice-carousel {
      max-width: 800px;
      width: 100%;
  
    }
  }
}


@media screen and (max-width: 800px) {
  .example {
    
    &__slider .alice-carousel {
      max-width: 500px;
      width: 100%;
  
    }
  }
}

@media screen and (max-width: 626px) {
  .example {
    margin-bottom: 30px;
  
  
    &__title {
     margin-bottom: 30px; 
    }
  
    &__slider .alice-carousel {
      max-width: 350px;
      width: 100%;
  
    }

    &__slider .alice-carousel__stage-item img{
      width: 214px;
      height: 283px;
    }
  }
}


@media screen and (max-width: 500px) {
  .example {
    
  
  
    &__title {
      color: #4D4D4D;

      font-family: Unbounded;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 45px; /* 225% */
      text-transform: uppercase;
    }

    &__slider .alice-carousel {
      max-width: 300px;
      width: 100%;
  
    }
    &__slider .alice-carousel__stage-item,
    &__slider .alice-carousel__stage-item img{
      width: 214px;
      height: 283px;
    }
  
    &__slider .alice-carousel__prev-btn-item {
      left: 0px;
      width: 41px;
      height: 41px;
    }
  
    &__slider .alice-carousel__prev-btn-item span::after {
      content: '';
      width: 41px;
      height: 41px;

      background-size:  41px;
 
    }
  
    &__slider .alice-carousel__next-btn-wrapper {
      right: -20px;
    }
  
    &__slider .alice-carousel__next-btn-wrapper span::after {
      content: '';
      width: 41px;
      height: 41px;

      background-size:  41px;
  
    }
  }
}