.gallery {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1062px;
  margin: 0 auto;
}

.gallery__title {
  color: #4d4d4d;

  font-family: Unbounded;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  text-transform: uppercase;

  margin-bottom: 60px;
}

.categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.category-btn {
  border: 1px solid black;
  background: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-btn.active {
  background: linear-gradient(
    104deg,
    #ff7e04 39.96%,
    rgba(255, 245, 4, 0.5) 133.03%
  );
  color: white;
  border: none;
}

.category-btn:hover {
  border-color: #ff7e04;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  width: 100%;
  visibility: visible;
}

.image-grid-hide {
  visibility: hidden;
}

.gallery .spinner {
  align-self: center;

  padding-top: 50px;
  margin: 0 auto;
}

.image-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.image-item img {
  width: 240px;
  height: 295px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-grid {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .gallery h1 {
    font-size: 18px;
  }

  .gallery__title {
    margin-bottom: 30px;
    color: #4d4d4d;

    font-family: Unbounded;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    text-transform: uppercase;
    text-align: start;
  }

  .categories {
    gap: 15px;
    justify-content: flex-start;
  }

  .category-btn {
    padding: 10px;
    font-size: 14px;
  }



  .image-item img {
    border-radius: 8px;
    width: 250px;
  }
}
