.unique {
  background-color: #F3F3F3;
  height: 608px;

  margin-bottom: 170px;
 

  &__container {
    position: relative;
    top: 76px;
    max-width: 1064px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 24px;
    

    &-img {
      position: relative;
      max-width: 450px;
      top: -40px;
      right: 50px;
    }

    & .utp {
      position: relative;
      border-radius: 55px;
      background: linear-gradient(135deg, #FF7E04 33.67%, rgba(255, 245, 4, 0.50) 144.26%);
      box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.10);

      box-sizing: border-box;
      width: 658px;
      height: 556px;
      padding: 50px 55px;
      overflow: hidden;

      

      &__title {
        color: #FFF;

        font-family: Unbounded;
        font-size: 40px;
        font-weight: 500;
        line-height: 45px; 
        text-transform: uppercase;
        display: flex;
        flex-direction: column;


        & span {
          position: relative;
          transform: translateX(20%);
        }
      }

      &__arrow {
        position: relative;
        top: -20px;
      }

      &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        right: -150px;
        top: -110px;
      
        &-num {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          
          &-img {
            position: relative;
            right: 20px;
          }

          &-title {
            color: #FFF;

            font-family: Unbounded;
            font-size: 100px;
            font-weight: 500;
            line-height: 45px;
            text-transform: uppercase;
          }
        }
        &-text {
          width: 100%;
          color: #FFF;
          text-align: start;
          font-family: Unbounded;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          max-width: 296px;
        }
        
      }

      & .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        top: -20px;

        &__label {
          position: relative;

          & .error {
            bottom: 15px;
            color: #636363;
          }
        }

        &__input {
          max-width: 240px;
          width: 100%;


          border-radius: 19px;
          border: 2px solid #FFF;
          background-color: transparent;

          color: #FFF;

          text-align: center;
          font-family: Unbounded;
          font-size: 18px;
          font-weight: 400;
          line-height: 109.4%; 
          padding: 20px 15px;
          box-sizing: border-box;
          outline: none;
          margin-bottom: 40px;

          &::placeholder {
            color: #FFF;

            text-align: center;
            font-family: Unbounded;
            font-size: 18px;
            font-weight: 400;
            line-height: 109.4%; 
          }

        }

        &__button {
          width: 100%;

          border-radius: 19px;
          border: 2px solid #FFF;
          background: #FFF;
          color: #636363;

          text-align: center;
          font-family: Unbounded;
          font-size: 25px;
          font-weight: 400;
          line-height: 109.4%;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .unique {

   
  
    &__container {

  
      &-img {
        max-width: 350px;
      }
  
      & .utp {
        max-width: 550px;
        padding: 20px 25px;
        
  
        &__title {

          font-size: 35px;
        }
  
        &__container {

        
          &-num {
  
            &-title {
              font-size: 80px;
            }
          }          
        }
      }
    }
  }
}


@media screen and (max-width: 930px) {
  .unique {
    height: 900px;
   
  
    &__container {
      top: -20px;
      flex-direction: column-reverse;
      align-items: center;
  
      &-img {
        max-width: 350px;
        content: url('../../image/utp/utp-mobile.webp');

        right: 0;
      }
  
      & .utp {
        max-width: 550px;
        padding: 20px 25px;

        
  
        &__title {
          font-size: 35px;

        }
  

  
        &__container {

        
          &-num {
   
            &-title {
              font-size: 80px;
            }
          }

          
        }
  
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .unique {
    height: 700px;
   
  
    &__container {
      
      
  
      &-img {
        max-width: 300px;
      }
  
      & .utp {
        max-width: 316px;
        width: 100%;
        height: 500px;

        .form__input {
          max-width: 100%;
        }
  
        &__title {
          color: #FFF;

          font-family: Unbounded;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px; 
          text-transform: uppercase;
        }
  
        &__arrow {
          width: 62px;
          height: 62px;
          left: -10px;
        }
  
        &__container {
          top: -60px;
          right: -80px;
        
          &-num {
            
            
            &-img {
              width: 48px;
              height: 53px;
              right: 5px;
            }
  
            &-title {
              color: #FFF;

              font-family: Unbounded;
              font-size: 50px;
              font-weight: 500;
              line-height: 45px;
              text-transform: uppercase;
            }
          }
          &-text {
            color: #FFF;

            font-family: Unbounded;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px; 
            text-transform: uppercase;
          }
          
        }
      }
    }
  }
}


@media screen and (max-width: 350px) {
  .unique {
    height: 700px;
   
  
    &__container {
      
      
  
      &-img {
        max-width: 300px;
      }
  
      & .utp {
        max-width: 316px;
        width: 100%;
        height: 500px;

        
  
        &__title {
          color: #FFF;

          font-family: Unbounded;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px; 
          text-transform: uppercase;
          
        }
  
        &__arrow {
          width: 62px;
          height: 62px;
          
        }
  
        &__container {
          top: -60px;
          right: -60px;
        
          &-num {
            
            
            &-img {
              width: 48px;
              height: 53px;
              right: 5px;
            }
  
            &-title {
              color: #FFF;

              font-family: Unbounded;
              font-size: 50px;
              font-weight: 500;
              line-height: 45px;
              text-transform: uppercase;
            }
          }
          &-text {
            color: #FFF;

            font-family: Unbounded;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px; 
            text-transform: uppercase;
          }
          
        }
  
      }
    }
  }
}