.services {
  margin: 0 auto;
  padding: 24px;
  margin-bottom: 70px;
  position: relative;
  overflow: hidden;

  &__container {
    max-width: 1064px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
  }

  &__bg1,
  &__bg2 {
    width: 100%;
    background-color: #f3f3f3;
    position: absolute;
    z-index: 1;
    display: none;
    height: 100%;
  }

  &__bg1 {
    top: 70px;
    transform: rotate(-5deg);
    min-height: 865px;
  }

  &__bg2 {
    top: 200px;
    min-height: 2605px;
  }

  &__title {
    color: #4d4d4d;
    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
    text-transform: uppercase;
    position: relative;
    z-index: 99;
    margin-bottom: 32px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 30px;
    justify-items: center;
    align-items: center;
    z-index: 99;
  }

  &__item {
    max-width: 270px;
    width: 100%;
    position: relative;
    text-align: center;
    height: 397px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    max-width: 250px;
    width: 100%;
    height: 320px;
    border-top-left-radius: 12px;

    &-standart {
      height: auto;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 250px;
      justify-self: center;
    }

    &-center {
      grid-column: 2 / 3;
    }

    &-rounded {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &__description {
    width: 100%;
    padding: 0 15px;
    height: 45px;
    background: linear-gradient(
      106deg,
      #ff7e04 38.78%,
      rgba(255, 245, 4, 1) 135.92%
    );
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 19px;
    border: none;
  }
}

@media screen and (max-width: 883px) {
  .services {
    margin-top: 250px;
    margin-bottom: 100px;

    &__list {
      grid-template-columns: repeat(
        auto-fit,
        minmax(270px, 1fr)
      );
    }
  }
}

@media screen and (max-width: 600px) {
  .services {
    margin-top: 300px;
    padding-left: 25px;
    margin-bottom: 0;

    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      width: 80%;
    }

    &__description {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 330px) {
  .services {
    &__list {
      grid-template-columns: 1fr; 
      gap: 20px;
    }

    &__img {
      width: 100%; 
      height: auto; 
    }

    &__description {
      font-size: 12px;
    }
  }
}
