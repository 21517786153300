.header {
  //
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 200;
}

.header__container {
  width: 100%;
  max-width: 1050px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.nav {
  max-width: 304px;
  width: 100%;
  // padding-left: 10px;

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  &__link {
    color: #000;
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: #ff7e04;
      transition: all 0.3s;
    }
  }
}

@keyframes showMenu {
  0% {
    height: 0;
    transition: all 0.5s;
  }
  100% {
    height: calc(100vh - 120px);
    transition: all 0.5s;
  }
}

.header__mobile {
  display: none;
  position: relative;

  & .nav-mobile-menu {
    position: fixed;
    top: 120px;
    left: 0;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    padding-top: 100px;
    z-index: 1;


    animation-name: showMenu;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    & .nav__list {
      display: flex;
      flex-direction: column;
      padding-top: 25px;
    }

    & .nav__item {
      margin-bottom: 25px;
    }
  }

  & .fixed-mobile {
    top: 60px;
  }
}

.fixed {
  position: fixed;
  top: 0;
  margin: 0 auto;
  background-color: #fff;
}

.order {
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 445px;
    width: 100%;
    position: relative;
    z-index: 99;
  }

  &__item-icon {
    display: block;
    width: 40px;
    height: 40px;
    background-image: url(../../image/icons/insta.webp);
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
    background-size: 40px;
    cursor: pointer;

    &:hover {
      background-image: url(../../image/icons/insta-hover.webp);
      transition: all 0.3s;
    }
  }

  &__link {
    text-decoration: none;
    color: #3d3d3d;

    font-family: Unbounded;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s;

    &:hover {
      color: #ff7e04;
      transition: all 0.3s;
    }
  }
}

@media screen and (max-width: 980px) {
  .nav {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .nav {
    max-width: 350px;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .header.desktop {
    display: none;
  }

  .header__mobile {
    display: flex;

    & .order__list {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .header__mobile {
    & .order__link {
      font-size: 12px;
    }
  }
}
