.main {
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 23px;
  position: relative;
  z-index: 99;

  &__container {
    margin-bottom: 161px;
    border-radius: 55px;
    background-color: #F3F3F3;
    width: 100%;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.08);

    padding: 65px 83px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;

    .slick-dots button::before {
      width: 15px;
      height: 15px;
      border: 1px solid #4D4D4D;
      border-radius: 50%;
      font-size: 0;
      opacity: 1;
    }

    .slick-dots .slick-active {
      & button::before {
        background-color: #4D4D4D;
      }
    }

    &-mobile {
      display: none;
    }
  }

  .main__description {
    display: flex;
    flex-direction: column;
    max-width: 501px;
    padding-right: 10px;
  }

  .main__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 60px;
    font-weight: 500;
    line-height: 66px;
    text-transform: uppercase;
  }

  .main__text {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 30px;
    font-weight: 300;
    line-height: 118.9%;

    flex-grow: 2;
  }

  .order {
    padding: 27px 40px;
    border-radius: 24px;
    background: linear-gradient(103deg, #FF7E04 51.16%, rgba(255, 245, 4, 0.50) 147.52%);
    display: flex;
    justify-content: space-between;
    transition: all .3s;

    &__text {
      color: #FFF;

      max-width: 201px;
      font-weight: 400;
      line-height: 119.9%; 
    }

    &__button {
      border: 1px solid #FFF;
      background: transparent;

      &:hover {
        background-color: transparent;
      }

    }
  }

  &__slider {
      max-width: 407px;
      width: 100%;
      height: 509px;
      position: relative;

    &-image {
      max-width: 477px;
      width: 100%;
      height: 509px;
      border-radius: 40px;
    }

    .slick-list {
      max-width: 477px;
      width: 100%;
      height: 509px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .main {
    
  
    &__container {
      padding: 40px 40px;
    }
  
    .main__description {
      max-width: 411px;
    }
  
    .main__title {
      font-size: 40px;
    }
  
    .main__text {
      font-size: 25px;
    }
  
    .order {
      padding: 25px 25px;
  
      &__text {
        font-size: 15px;
        max-width: 120px;
      }
  
      &__button {
        
        padding: 10px;
      }
    }
  
    &__slider {
      max-width: 357px;
      height: 409px;
  
      &-image {
        height: 409px;
      }
  
      .slick-list {
        max-width: 377px;
        height: 409px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .main {
    
  
    &__container {
      &-decktop {
        display: none;
      }

      &-mobile {
        display: flex;
        justify-content: center;

      }
    }
  
    .main__description {
      display: flex;
      padding: 0;
    }
  
    .main__title {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .main__text {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .order {
  
      &__text {
      }
  
      &__button {
        
      }
    }
  
    &__slider {
      max-width: 337px;
      height: 409px;
      margin-bottom: 50px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      .slick-dots button::before {
        width: 15px;
        height: 15px;
        border: 1px solid #4D4D4D;
        border-radius: 50%;
        font-size: 0;
        opacity: 1;
      }

      .slick-dots .slick-active {
        & button::before {
          background-color: #4D4D4D;
        }
      }
  
      &-image {
        height: 409px;
      }
  
      .slick-list {
        max-width: 377px;
        height: 409px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .main {
    margin-bottom: 30px;
  
    &__container {
      margin-bottom: 40px;

      &-mobile {
       
        padding: 20px;
        
      }
    }
  
    .main__description {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .main__title {
      font-size: 30px;
      line-height: 35px
    }
  
    .main__text {
      font-size: 14px;
      max-width: 250px;
    }
  
    .order {
      max-width: 276px;
      &__text {
        color: #FFF;

        font-family: Unbounded;
        font-size: 12px;
        font-weight: 400;
        line-height: 119.9%;
      }
  
      &__button {
        color: #FFF;

        text-align: center;
        font-family: Unbounded;
        font-size: 12px;
        font-weight: 400;

        
      }
    }
  
    &__slider {
      width: 246px;
      height: 295px;
      left: 0;
      transform: translateX(0);


  

     
  
      &-image {
        width: 276px;
        height: 295px;
      }
  
      .slick-list {
        width: 276px;
        height: 295px;
      }
    }
  }
}