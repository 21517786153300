.reviews {
  
  padding: 0 24px;
  margin-bottom: 50px;

  &__container {
    max-width: 1062px;
    margin: 0 auto;
  }

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 100px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  } 

  .reviews__item {
    width: 100%;
    padding: 44px 57px;
    box-sizing: border-box;
    border-radius: 26px;
    display: flex !important;
    justify-content: space-between;
    gap: 20px;
    max-height: 418px;
    

  }

  &__black {
    background-color: #EFEFEF;
  }

  &__white {
     background-color: #EFEFEF;
    flex-direction: row-reverse;

    & .reviews__ordered {
      margin-left: 0;
    }
  }

  &__picture {
    position: relative;
    

  }

  &__info {
    max-width: 548px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name {
    color: #FF7E04;

    font-family: Unbounded;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px; 
    text-transform: uppercase;

    margin-bottom: 50px;
  }

  &__review {
    color: #787878;

    font-family: Unbounded;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px; 

    margin-bottom: 25px;
  }

  &__ordered {
    color: #FFF;

    text-align: center;
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 400;
    line-height: 109.4%;

    margin-left: auto;
  }

  & .slick-slider {
    width: 100%;
  }

}

@media screen and (max-width: 800px) {
  .reviews {

    &__item {
      padding: 44px 25px;
    }
  }
  
}

@media screen and (max-width: 700px) {
  .reviews {
  
    .reviews__item {
      padding: 30px 20px;
      max-width: 100%;
     height: 370px;
    
    }
  
    &__picture {
      max-width: 200px;
      height: 270px;
      top: 0;
      margin-bottom: 0;
    }

    &__review {
      margin-bottom: auto;

    }
    .reviews__info {
      height: 100%;
    }
  }
}

@media screen and (max-width: 581px) {
  .reviews {
  
    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__item {
      padding: 30px 20px;
      flex-direction: column;
      align-items: center;
      max-height: 100% !important;
      height: 620px !important;
      justify-content: flex-start;
    }
  
    &__white {
   
      & .reviews__ordered {
        margin: 0 auto;
      }
    }
  
    &__picture {
      margin: 0;
      margin-bottom: 28px;
    }
  
    &__name {
      color: #FF7E04;

      font-family: Unbounded;
      font-size: 20px;
      font-weight: 500;
      line-height: 45px; 
      text-transform: uppercase;

      margin-bottom: 15px;
    }
  
    &__review {
      color: #787878;

      font-family: Unbounded;
      font-size: 10px;
      font-weight: 400;
      line-height: 16.5px;
    }
  
    &__ordered {
      color: #FFF;

      text-align: center;
      font-family: Unbounded;
      font-size: 18px;
      font-weight: 400;
      line-height: 109.4%;

      margin: 0 auto;
    }
  }
  
}