@font-face {
  font-family: 'VeraHumana';
  src: url('./verahumana/verahumana95.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VeraHumana';
  src: url('./verahumana/verahumana95bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'VeraHumana';
  src: url('./verahumana/verahumana95bolditalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'VeraHumana';
  src: url('./verahumana/verahumana95italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

