.popup {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.4s ease, transform 0.4s ease;

  &--visible {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  &__content {
    background: linear-gradient(180deg, #fbb458, #fd7e25);
    width: 100%;
    height: 500px;
    max-width: 500px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
    overflow: hidden;
  }

  &__gift {
    position: absolute;
    z-index: 0;
    width: 158.5px;
    height: 158.5px;

    &--top {
      top: -40px;
      right: 20px;
      transform: rotate(200deg);
    }

    &--bottom {
      bottom: -50px;
      left: 20px;
      transform: rotate(10deg);
    }
  }

  &__line {
    position: absolute;
    z-index: 0;
    width: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font-size: 35px;
    color: #fff;
    margin-bottom: 15px;
    position: relative;
    z-index: 100;
    font-family: 'VeraHumana';
    font-weight: bold;
  }

  &__description {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;
    font-family: 'VeraHumana';
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    position: relative;
    z-index: 100;
  }

  &__button {
    padding: 0px 20px;
    padding-left: 0;
    font-size: 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: #3c1c22;
    transition: background-color 0.3s;
    background: #fec89b;
    width: 205px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    text-decoration: none;

    &--icon {
      width: 85px;
      height: 85px;
    }
  }

  &__thank-you {
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 100;
    font-family: 'VeraHumana';
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .popup__content {
    padding: 15px;
  }

  .popup__title {
    font-size: 20px;
  }

  .popup__button {
    font-size: 14px;
  }

  .popup__thank-you {
    font-size: 12px;
  }

  .popup__line {
    width: 100%;
    height: 100%;
  }

  .popup__button {
    width: 130px;

    &--icon {
      width: 50px;
      height: 50px;
    }
  }
}
