.app {
  width: 100%;
  position: relative;
  margin: 0 auto;
  // overflow: hidden;

  &__bgLine {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrapper {
    overflow: hidden;
    height: fit-content;
  }

  &__adv {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF7E04;
    color: #FFF;
    font-family: Unbounded;
    text-decoration: none;

    text-align: center;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    z-index: 99;

    margin-bottom: 56px;
  }

  &__container {
    position: relative;
    z-index: 99;
  }
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 850px) {
  .app {
  
    &__adv {  
      margin-bottom: 0px;
    }
  }
}


#utp {
  position: relative;
  top: -50px;
}