.footer {


  &__header {
    max-width: 1062px;
    padding: 24px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 43px;

    & img {
      width: 171px;
      height: 171px;
    }
  }

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
    text-transform: uppercase;
    max-width: 867px;
    width: 100%;

    &-orange {
      position: relative;
      margin-right: auto;
      color: #FF7E04;
      font-family: Unbounded;
      font-size: 40px;
      font-weight: 500;
      line-height: 45px;
      text-transform: uppercase;
      width: 100%;
      display: block;
      text-align: end;
    }

    &-start {
      display: inline;
    }

    &-black {
      position: relative;
      color: #4D4D4D;
      font-family: Unbounded;
      font-size: 40px;
      font-weight: 500;
      line-height: 45px;
      text-transform: uppercase;
    }
  }

  &__container {
    padding: 24px;
    max-width: 1060px;
    padding: 24px;
    margin: 0 auto;

  }

  &__form {
    padding: 30px 63px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 2px solid #8B8B8B;
    background-color: transparent;
    box-sizing: border-box;
    margin-bottom: 41px;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    position: relative;
  }

  &__input {
    border-radius: 19px;
    border: 2px solid #FF7E04;
    background: rgba(100, 60, 60, 0);
    color: #3b3a3a;

    text-align: center;
    font-family: Unbounded;
    font-size: 18px;
    font-weight: 400;
    line-height: 109.4%;
    outline: none;

    padding: 22px 10px;

    &::placeholder {
      color: #979797;
      text-align: center;
      font-family: Unbounded;
      font-size: 18px;
      font-weight: 400;
      line-height: 109.4%;
    }
  }

  & &__submit {
    padding: 20px 59px;
    box-sizing: border-box;

    color: #FFF;

    text-align: center;
    font-family: Unbounded;
    font-size: 24px;
    font-weight: 400;
    line-height: 109.4%;

    .button-disabled:disabled {
      opacity: .5;
      cursor: auto;
      background: #FF7E04;
    }
  }

  &__contacts {
    max-width: 1061px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 74px;

    &-div {
      display: flex;
    }

    &-phone {
      width: 32px;
      height: 32px;
      margin-right: 20px;
    }
  }

  &__numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: auto;

    & a {
      color: #636363;

      font-family: Unbounded;
      font-size: 14px;
      font-weight: 500;
      line-height: 125%; 
      text-decoration: none;
      transition: all .3s;

      &:hover {
        transition: all .3s;
        color: #000;
      }
    }
  }

  &__social {
    display: flex;
    max-width: 191px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & img {
      transition: all .5s;
      width: 32px;
      height: 32px;
    }

    & a:hover img {
      transition: all .5s;
    }

    &-insta img:hover {
      content: url('../../image/icons/insta-hover.webp');
      transition: all .3s;
    }

    &-facebook:hover img {
      content: url(../../image/icons/facebook.webp);
      transition: all .5s;
    }

    &-viber:hover img {
      content: url(../../image/icons/viber.webp);
    }

    &-telegram:hover img {
      content: url(../../image/icons/telegtam.webp);
    }
  }

  &__developer {
    margin: 0 auto;
    color: #8B8B8B;

    text-align: center;
    font-family: Unbounded;
    font-size: 18px;
    font-weight: 300;
    line-height: 142.045%;

    text-decoration: none;
    margin-bottom: 50px;
    display: block;
    transition: all .3s;

    &:hover {
      transition: all .3s;
      color: #000;
    }
  }

}

@media screen and (max-width: 1034px) {
  .footer {
    &__form {
      padding: 25px;
    }
  }
}


@media screen and (max-width: 950px) {
  .footer {
    &__form {
      flex-direction: column;
      width: auto;
    }

    &__label {
      margin-bottom: 40px;
    }  
  }
}


@media screen and (max-width: 900px) {
  .footer {

    &__header {

  
      & img {
        width: 71px;
        height: 71px;
      }
    }
  
    &__title {
      font-size: 30px;
  
      &-orange {
        font-size: 30px;
      }
  
      &-black {
        font-size: 30px;
      }
    }  
  }
}


@media screen and (max-width: 642px) {
  .footer {

    &__header {
      justify-content: center;
      position: relative;
  
      & img {
        width: 71px;
        height: 71px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
    }
  
    &__title {
      font-size: 20px;

      max-width: 346px;
  
      &-orange {
        font-size: 20px;
      }
  
      &-black {
        font-size: 20px;
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .footer {
  
    &__input {
      font-size: 14px;
    }
  
    & &__submit {
      font-size: 18px;
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-div {
        margin-bottom: 30px;
      }
    }
  
  }
}
