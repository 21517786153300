@font-face {
  font-family: 'Cyrvetica v2 Extra Outline';
  src: url("../..//fonts/139-font.otf") format("opentype");
  }
  body {
      font-family: 'Cyrvetica v2 Extra Outline';
  }

.reasons {
  
  padding: 0 24px;
  margin-bottom: 122px;

  &__container {
    max-width: 1062px;
    margin: 0 auto;
    border-radius: 55px;
    background: linear-gradient(116deg, #FF7E04 33.57%, rgba(255, 245, 4, 0.50) 147.47%);

    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 50px 120px;
    box-sizing: border-box;
  }

  &__header {
    max-width: 580px;
    display: flex;
    justify-content: space-between;
    color: #FFF;
    margin-bottom: 57px;

    &-title {
      font-family: Unbounded;
      font-size: 40px;
      font-weight: 600;
      line-height: 45px; 
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      flex-grow: 2;

      & span {
        margin-left: 20%;
        
      }
    }

    &-icon {
      max-width: 89px;
      max-height: 89px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    max-width: 385px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    position: relative;
  }

  &__num {
    font-family: Cyrvetica v2 Extra Outline;
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #fff; 
    display: inline-block;
    margin-right: 20px;
  }

  &__text {
    max-width: 304px;
    width: 100%;
    height: 139px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    padding: 10px 32px;
    box-sizing: border-box;
    border-radius: 25px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
   
    position: relative;

    & p {
      color: #787878;

      font-family: Unbounded;
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;

      position: relative;
      z-index: 99;

      & span {
        color: #787878;
        font-family: Unbounded;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  &__gift {
    position: relative;
    right: 0;
    top: 0;
    z-index: 1;
  }
} 

@media screen and (max-width: 1070px) {
  .reasons {
    &__list {
      justify-content: center;
    }
  }
}


@media screen and (max-width: 860px) {
  .reasons {
    &__container {
      padding: 30px 50px;
    }
  
  
    &__list {
      justify-content: center;
    }
  } 
}


@media screen and (max-width: 726px) {
  .reasons {

    &__container {
      padding: 24px;
    }
  
    &__list {
      justify-content: center;
    }
  } 
}

@media screen and (max-width: 670px) {
  .reasons {
  
    &__container {
      padding: 24px;
    }
  
    &__header {
      
      &-title {
        font-size: 30px;
      }
    }
  
    &__list {
      justify-content: center;
    }

  } 
}

@media screen and (max-width: 670px) {
  .reasons {
    margin-bottom: 40px;

    &__container {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    &__header {
      
      position: relative;
      max-width: 250px;
      width: 100%;
  
      &-title {
        font-size: 20px;
  
      }
  
      &-icon {
        width: 44px;
        height: 52px;

        position: absolute;
        top: 0;
        right: 20px;
      }
    }
  
    &__list {
      justify-content: center;
    }
  
    &__item {
      justify-content: center;
      
    }
  
    &__text {
      max-width: 211px;
      height: 111px;
      padding: 10px 20px;
  
      & p {
        color: #787878;

        font-family: Unbounded;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
  
        & span {
          color: #787878;
          font-family: Unbounded;
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
        }
      }
    }
  
    &__gift {
      max-width: 57px;
      height: 63px;
    } 
  } 
}