.faq {
  position: relative;

  padding-top: 100px;
  margin-bottom: 140px;
  
  max-height: 1400px;
  height: 100%;
  overflow: hidden;

  padding: 25px;
  padding-bottom: 40px;
  

  .faq__container {
    max-width: 1064px;
    padding: 24px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    top: 50px;
    
  }

  &__bg1 {
    background-color: #F3F3F3;
    width: 100%;
    height: 70%;
    position: absolute;
    top: 300px;
    left: 0;
    z-index: 1;
  }

  &__bg2 {
    transform: rotate(-10deg);
    background-color: #F3F3F3;
    width: 110%;
    height: 70%;
    position: absolute;
    top: 150px;
    left: 0;
    z-index: 1;
  }

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px; 
    text-transform: uppercase;
    position: relative;
    top: -50px;

    margin-bottom: 80px;
    max-width: 506px;
  }

  & &__accordion {
    position: relative;
    z-index: 99;
    border: none;

    box-shadow: none;
    
    background-color: transparent;
    margin-bottom: 20px;

    color: #FFF;

    font-family: Unbounded;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; 

    border-radius: 27px;

    &::before {
      display: none;
    }
  }

  & &__summary {
    background: linear-gradient(93deg, #FF7E04 47.85%, rgba(255, 245, 4, 0.50) 127.3%);
    border-radius: 27px;
    border: none;

    color: #FFF;

    font-family: Unbounded;
    font-size: 20px;
    font-weight: 600;
    line-height: 125%;
    height: 82px;
  }

  & &__details {
    border: none;
    background-color: transparent;
    color: #636363;

    font-family: Unbounded;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 125%; 
    
  }
}

@media screen and (max-width: 600px) {
  .faq {
    margin-bottom: 30px;
  
    &__title {
      color: #4D4D4D;

      font-family: Unbounded;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px; 
      text-transform: uppercase;

      margin-bottom: 60px;
    }
  
    & &__summary {
      color: #FFF;

      font-family: Unbounded;
      font-size: 14px;
      font-weight: 600;
      line-height: 125%;
    }
  
    & &__details {
      color: #636363;

      font-family: Unbounded;
      font-size: 12px;
      font-weight: 300;
      line-height: 125%;
      
    }
  }
}