.size {
  max-width: 1064px;
  margin: 0 auto;
  padding: 24px;
}

.size__title {
  color: #4d4d4d;
  font-family: Unbounded;
  font-size: 40px;
  font-weight: 500;
  line-height: 45px;
  text-transform: uppercase;
  position: relative;
  z-index: 99;
  margin-bottom: 32px;
}

.size__list {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.size__list-item {
  width: 100%;
  max-width: 250px;
  position: relative;
  padding-bottom: 19px;
}

.size__list-item-container {
  position: relative;
}

.size__list-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.size__button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: linear-gradient(130.97deg, #fbb458 11.33%, #fd7e25 73.24%);
  border: none;
  border-radius: 25px;
  font-family: Unbounded;
  font-size: 10.76px;
  font-weight: 400;
  line-height: 13.34px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  padding: 15px 10px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .size__title {
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 430px) {
  .size__list-item {
    max-width: 200px;
   
  }
}