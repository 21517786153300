.steps {
  max-width: 1064px;
  margin: 0 auto;
  padding: 0 24px;
  margin-bottom: 88px;

  &__header {
    display: flex;
    justify-content: space-between;
    max-width: 736px;
    
    margin-bottom: 52px;


    &-title {
      color: #4D4D4D;

      font-family: Unbounded;
      font-size: 40px;
      font-weight: 500;
      line-height: 45px;
      text-transform: uppercase;
      
    }

    &-icon {
      width: 122px;
      height: 143px;


    }
  }

  &__list {
    max-width: 1064px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    border: 2px solid #000;
    border-radius: 50px;
    background-color: #fff;

    

    &-item {
      border-right: 1px solid #000;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;  
      height: 95px;   

      &:last-child {
        border: none;
      }
    }

    &-decour {
      position: absolute;
      top: -50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FF7E04;
      font-family: Unbounded;
      font-size: 80px;
      font-weight: 600;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }

    &-img {
      margin-left: 10px;
      width: 63px;
      height: 63px;
    }

    &-title {
      color: #474747;

      font-family: Unbounded;
      font-size: 22px;
      font-weight: 400;
      line-height: 98.4%;
      text-align: center;
    }

    &-text {
      margin-top: 10px;
      max-width: 240px;
      text-align: center;
    }

  }
}

@media screen and (max-width: 900px) {
  .steps {
  
    &__list {
  
      flex-wrap: wrap;
      
  
      &-item {
        width: 49%;
        margin-bottom: 100px;
  
        &:nth-child(2) {
          border: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .steps {

  
    &__header {
      
  
  
      &-title {
  
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;  
      }
  
      &-icon {

        width: 69px;
        height: 81px;
  
      }
    }
  
    &__list {
  
      flex-direction: column;
      align-items: center;
      
  
      &-item {
        width: 100%;
        margin-bottom: 100px;
        border: none;
        max-width: 230px;
        padding-bottom: 20px;

        border-bottom: 1px solid #000;

        margin-bottom: 120px;

        &:nth-child(2),
        &:nth-child(3) {
          border-bottom: 1px solid #000;
          margin-bottom: 120px;
        }
  
        &:nth-child(4) {
          border: none;
        }
      }
    }
  }
}