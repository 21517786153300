.price-included {
  max-width: 1061px;
  margin: 0 auto;
  padding: 24px;

  margin-bottom: 50px;

  &__header {
    display: flex;
    justify-content: space-between;
    max-width: 622px;
    margin-bottom: 30px;
  }

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px; 
    text-transform: uppercase;
  }

  &__icon {
    max-width: 114px;
    max-height: 114px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15); 

      border-radius: 55px;
      background-color: #FAFAFA;

      max-width: 330px;
      width: 100%;
      height: 210px;

      margin-bottom: 50px;

      &-container {
        display: flex;
        color: #FF7E04;
        align-items: center;

        font-family: Unbounded;
        font-size: 80px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-bottom: 26px;

        & img {
          width: 65px;
          height: 65px;
        }
      }

      &-text {
        color: #414141;

        text-align: center;
        font-family: Unbounded;
        font-size: 16px;
        font-weight: 500;
        line-height: 109.4%;

        max-width: 240px;
      }
    }
  }
}

@media screen and (max-width: 1037px) {
  .price-included {
  
    &__list {
      justify-content: space-around;
   
    }
  }
}

@media screen and (max-width: 600px) {
  .price-included {
    margin-bottom: 20px;

  
    &__header {
      justify-content: flex-start;
    }
  
    &__title {
      font-size: 20px;
    }
  
    &__icon {
      width: 56px;
      height: 56px;
    }
  
    &__list {
      justify-content: center;
  
     
    }
  }
}

@media screen and (max-width: 420px) {
  .price-included {
  
    &__list {
      
      

      &-item {
        max-width: 250px;
        height: 150px;

        &-container {
          margin-bottom: 8px;
          font-size: 60px;
        }
  
        &-text {
          font-size: 14px;
          padding-bottom: 20px;
        }
      }
    }
  }
}